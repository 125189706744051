export const isEmpty = function (v) {
  if (v) {
    if (typeof v == 'string') {
      v = v.trim();
    }
    return (v.length === 0);
  }

  return true;
};

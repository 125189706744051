import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars({
  type,
  open,
  setOpen,
  snackMessage,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;

  // const handleClick = (newState) => () => {
  //   setState({ open: true, ...newState });
  // };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {type === "success" && (
        <Snackbar
          open={open}
          autoHideDuration={3500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={vertical + horizontal}
        >
          <Alert severity="success">{snackMessage}</Alert>
        </Snackbar>
      )}
      {type === "error" && (
        <Snackbar
          open={open}
          autoHideDuration={3500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={vertical + horizontal}
        >
          <Alert severity="error">{snackMessage}</Alert>
        </Snackbar>
      )}
    </div>
  );
}
